@import "styles/variables";

a {
  transition: all $commonTransition;
  color: $subtextFontColor;
  &:hover {
    color: $primaryTextColor;
  }
}

.serviceItemContainer {
  display: flex;
  margin: 32px 16px 0;
  text-align: center;
}

.content {
  flex: 1;
  font-weight: 600;
}

.counties {
  color: $specialColor;
}

blockquote.quote.quote { // Cheating a bit with naming for specificity
  border-top: 1px solid $primaryTextColor;
  font-size: 0.875em;
  justify-content: center;
  min-height: 320px;
}

.splitList {
  text-align: left;
  display: inline-block;
}

.note {
  color: $subtextFontColor;
  font-size: 0.875em;
  font-weight: 300;

  > strong {
    font-weight: bold;
  }
}

@media (min-width: $xlSize) {
  .serviceItemContainer {
    margin-left: auto;
    margin-right: auto;
    width: $xlSize;
  }
}

@media (max-width: $lgSize) {
  .serviceItemContainer {
    flex-direction: column;
  }

  .splitList {
    column-count: 2;
    column-gap: 32px;
  }
}
