//noinspection CssInvalidAtRule
@import-normalize;
@import "styles/variables";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: $backgroundColor;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: $primaryTextColor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  color: $primaryTextColor;
  width: 100%;
}