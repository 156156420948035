@import "styles/variables";

.root {
  margin: 32px 16px;

  a {
    transition: all $commonTransition;
    color: $subtextFontColor;
    &:hover {
      color: $primaryTextColor;
    }
  }

  h2 {
    margin: 0;
  }

  .grid {
    flex: 1;
    display: flex;
  }

  .col {
    flex: 1;
    margin-bottom: 16px;
  }

  address {
    font-style: normal;
    color: $hintFontColor;
  }

  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //margin: -8px;
  }

  button, input, textarea {
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    border: 0;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  textarea, input {
    padding: 8px;
  }

  textarea {
    resize: vertical;
  }

  .field {
    padding: 8px;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;

    label {
      display: block;
      margin-bottom: 8px;
    }
  }

  .col {
    flex: 1
  }

  .icon {
    margin-right: 8px;
  }

  .hint {
    margin-top: 8px;
    font-size: 0.875em;
    color: $hintFontColor;
  }

  .error {
    color: $subtextFontColor;
  }

  .successBackground {
    background: #39ad48 !important;
  }

  button[type='submit'] {
    background: $primaryColor;
    transition: all $commonTransition;
    color: $primaryTextColor;
    font-size: 1.2em;
    padding: 8px 16px;
    text-transform: uppercase;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:hover {
      background: lighten($primaryColor, 5%);
    }

    &:disabled {
      background: lighten($primaryColor, 50%);
      cursor: default;
    }
  }
}

@media (min-width: $xlSize) {
  .root {
    margin-left: auto;
    margin-right: auto;
    width: $xlSize;
  }
}

@media (max-width: $lgSize) {
  .root {
    .grid {
      flex-direction: column;
      text-align: center;
    }

    form {
      flex-direction: column;
    }
  }
}