@import "styles/variables";

$homeAdvisorColor: #f7901e;

.root {
  margin: 16px;
}

.testimonialContainer {
  display: flex;
  align-items: baseline;
}

.logo {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.ratingContainer {
  white-space: pre;
  margin-right: 32px;

  .ratingText {
    margin: 4px 0;
  }

  .rating {
    color: $homeAdvisorColor;
    font-weight: 600;
  }
}

.star {
  color: $homeAdvisorColor;
  margin-right: 2px;
}

blockquote {
  text-indent: 2em;
}

@media (max-width: $mdSize) {
  .testimonialContainer {
    flex-direction: column;
  }

  .ratingContainer {
    align-self: center;
    margin-right: 0;
  }
}

@media (min-width: $xlSize) {
  .root {
    margin-left: auto;
    margin-right: auto;
    width: $xlSize;
  }
}

.reviewContainer {
  h1 {
    text-align: center;
    text-decoration: underline;
    margin-bottom: 16px;
  }

  .reviews {
    display: flex;
    flex-direction: row;

    img {
      width: 100%;
      max-width: 240px;
    }

    & > a {
      flex-basis: 33.333%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }

    .googleReview {
      background: white;
      padding: 12px;
      border-radius: 8px;
      font-family: 'Lexend', sans-serif;
      font-size: 3em;
      line-height: 1em;
      font-weight: 500;
      color: #4f5050;

      .googleText > span {
        &:nth-child(1) {
          color: #4682c1;
        }

        &:nth-child(2) {
          color: #e84737;
        }

        &:nth-child(3) {
          color: #f7bd1f
        }

        &:nth-child(4) {
          color: #4682c1
        }

        &:nth-child(5) {
          color: #35a955
        }

        &:nth-child(6) {
          color: #e84737
        }
      }
    }

    .homeAdvisorReview {
      border-radius: 8px;
      background: #313131;
      padding: 16px;
    }
  }



  @media (max-width: $lgSize) {
    .reviews {
      flex-direction: column;

      & > a {
        margin-top: 24px
      }
    }
  }
}
