@import "typography";

$backgroundColor: #b79366;

$xsSize: 320px;
$smSize: 480px;
$mdSize: 600px;
$lgSize: 1024px;
$xlSize: 1200px;

$largeGutter: 32px;
$smallGutter: 16px;

$commonTransition: 250ms cubic-bezier(0.4, 0, 0.2, 1);

$sectionBoxShadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);