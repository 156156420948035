@import "styles/variables";

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.title {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;

  .titleItem {

  }

  &.vhOverride {
    min-height: calc(100vh - 56px);
  }

  background: url("images/title.jpg") no-repeat center center;
  background-size: cover;
  box-shadow: 0px -4px 11px 3px rgba(0, 0, 0, 0.5);

  .text {
    text-shadow: 1px 2px 3px black;
    max-width: 100vw;
    padding: $largeGutter;

    h1 {
      display: inline-block;
      font-size: 3.75em;
      line-height: 0.875em;
      text-transform: uppercase;
      margin-bottom: 0;

      hr {
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }

    h2 {
      color: $subtextFontColor;
      margin-top: 16px;
      font-size: 2em;
      line-height: 1em;
      max-width: 12em;
    }
  }

  .contact {
    text-shadow: 1px 2px 3px black;
    position: absolute;
    right: $largeGutter;
    bottom: ($largeGutter / 2);
    text-align: right;

    h4 {
      text-transform: uppercase;
      margin-bottom: 0;
      display: inline-block;

      hr {
        margin-top: 1px;
        margin-bottom: 1px;
      }
    }

    address {
      font-style: normal;

      .icon {
        padding-right: 4px;
        box-sizing: content-box;
      }

      a {
        text-shadow: none;
        filter: drop-shadow(0px 0px 3px black);
        transition: all $commonTransition;
        color: $subtextFontColor;
        font-weight: 600;
        line-height: 2.4;
        display: inline-flex;
        align-items: center;

        &:hover {
          color: white;
          filter: drop-shadow(0px 0px 6px black);
        }
      }
    }
  }

  .scrollIcon {
    position: absolute;
    bottom: $smallGutter;
    left: 50%;
    margin-left: -10px;
    width: 20px;
    animation: bounce 2s infinite;
  }
}

@media (min-width: $lgSize) {
  .title {
    .text {
      padding: 0 0 0 $largeGutter;
    }
  }

  .contact {
    h4 {
      font-size: 2em;
    }

    address {
      font-size: 1.2em;
    }
  }
}

@media (min-width: $xlSize) {
  .title {
    .titleItem {
      margin-left: auto;
      margin-right: auto;
      width: $xlSize;
      position: relative;
    }

    .text {
      padding-left: $smallGutter;
    }

    .contact {
      right: $smallGutter;
    }
  }
}

@media (max-width: $smSize) {
  .title {
    .text {
      padding: $smallGutter;
      text-align: center;
      margin-top: -30vh;

      h1 {
        font-size: 2.2em;
      }

      h2 {
        font-size: 1.5em;
        display: inline-block;
      }
    }

    .contact {
      right: $smallGutter;
      bottom: 96px;

      address > a {
        filter: none;
        transition: none;
        text-shadow: 4px 2px 6px black;

        &:hover {
          filter: none;
        }
      }
    }
  }
}