$primaryColor: #8b2c1a;
$primaryTextColor: white;

$secondaryColor: #3c6865;
$secondaryTextColor: white;

$subtextFontColor: #fffba7;
$hintFontColor: rgba(255, 255, 255, 0.75);

$specialColor: #e3ef85;

h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
}

blockquote {
  margin-left: 16px;
  margin-right: 16px;

  footer {
    margin-top: 4px;
    color: #d5d5d5;
    &:before {
      content: "\2014\00A0";
    }

    cite {
      font-style: italic;
    }
  }
}
