@import "styles/variables";

.root {
  margin: 32px 16px;

  button, button:hover, button:hover:enabled {
    background-color: $primaryColor;
  }

  ul > li {
    overflow: hidden;
  }
}

@media (min-width: $xlSize) {
  .root {
    margin-left: auto;
    margin-right: auto;
    width: $xlSize;
  }
}

.galleryItemContainer {
  position: relative;

  .note {
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
    text-align: center;

    text-shadow: rgb(0, 0, 0) 0 0 4px, rgba(0, 0, 0, 0.5) 0 0 8px;
    font-size: 36px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

@media (max-width: $mdSize) {
  .galleryItemContainer {
    width: 100%;

    img {
      width: 100%;
      max-width: 500px;
    }
  }
}
