@import "styles/variables";

.root {
  margin: 16px;
  flex-basis: 33%;
  background-color: $secondaryColor;
  box-shadow: $sectionBoxShadow;
  display: flex;
  flex-direction: column;

  h3 {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    font-size: 2em;
    color: $primaryColor;
    background-color: white;
    margin: 0;
    padding: 16px;
    text-align: center;

    .icon {
      padding-right: 16px;
      box-sizing: content-box;
    }
  }

  .body {
    padding: 16px;
    white-space: pre-line;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
    flex: 1;

    p {
      margin: 8px 0 32px 0;
    }

    blockquote {
      padding-top: 16px;
      text-indent: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}

@media (max-width: $lgSize) {
  .root {
    flex-basis: unset;
  }
}

@media (max-width: $smSize) {
  .root {
    margin-left: 0;
    margin-right: 0;
  }
}